import clsx from 'clsx';
import {
  PreventFlashOnWrongTheme,
  Theme,
  ThemeProvider,
  useTheme,
} from 'remix-themes';
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react';

import { json, LinksFunction, LoaderFunction } from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from '@remix-run/react';

import { CommonErrorBoundary } from '~/components/error-boundary';
import { themeSessionResolver } from '~/lib/sessions.server';
import { cn } from '~/lib/utils';

import { Providers } from './components/providers';
import { csrf } from './lib/server/csrf.server';
import styles from './tailwind.css?url';

export const links: LinksFunction = () => {
  return [{ rel: 'stylesheet', href: styles }];
};

export const loader: LoaderFunction = async ({ request }) => {
  const { getTheme } = await themeSessionResolver(request);
  const [token, cookieHeader] = await csrf.commitToken();

  return json(
    {
      token,
      theme: getTheme(),
    },
    {
      headers: {
        'Set-Cookie': cookieHeader!,
      },
    },
  );
};

function App() {
  const data = useLoaderData<typeof loader>();
  const [theme] = useTheme();

  return (
    <html lang="en" className={clsx(theme)}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <PreventFlashOnWrongTheme ssrTheme={Boolean(theme)} />
        <Links />
      </head>
      <body className={cn(theme === Theme.DARK && 'dark')}>
        <Providers>
          <AuthenticityTokenProvider token={data.token}>
            <Outlet />
          </AuthenticityTokenProvider>
        </Providers>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <ThemeProvider specifiedTheme={data.theme} themeAction="/action/set-theme">
      <App />
    </ThemeProvider>
  );
}

export const ErrorBoundary = CommonErrorBoundary;
